import React from "react";
import { ROUTE_PATHS } from "./RoutePaths";

const Home = React.lazy(() => import("../pages/Home"));
const Stream = React.lazy(() => import("../pages/Stream"));

const OpenRoutes = [
	{
		path: ROUTE_PATHS.home,
		exact: true,
		name: "Home",
		component: Home,
	},
	{
		path: ROUTE_PATHS.stream,
		exact: true,
		name: "Stream",
		component: Stream,
	},
];

export { OpenRoutes };
