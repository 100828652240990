import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { ROUTE_PATHS } from "./routes/RoutePaths";
import { OpenRoutes } from "./routes/Routes";

function App() {
	let Routes = OpenRoutes;

	return (
		<Router>
			<div
				style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
			>
				<div style={{ flex: 1 }}>
					<React.Suspense fallback={() => <span></span>}>
						<Switch>
							{Routes.map((route, index) => {
								return (
									<Route
										key={index}
										path={route.path}
										exact={route.exact}
										name={route.name}
										render={(props) => <route.component {...props} />}
									/>
								);
							})}
							<Redirect to={ROUTE_PATHS.home} />
						</Switch>
					</React.Suspense>
				</div>
			</div>
		</Router>
	);
}

export default App;
